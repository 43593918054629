@import "../../styles/style-guide.scss";

// ------------------------------Responsiveness----------------------
@media only screen and (min-width: 1824px) {
  .section_title {
    @include heading2;
  }
  .services {
    flex-direction: row;
    gap: 4rem;
  }
  .home_hyperlink .home_hyperlink_text {
    @include hyperlinkL;
  }
  .latest_articles {
    // flex-direction: column;
    // gap: 3rem;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 4rem;
    row-gap: 4rem;
  }
}
@media (min-width: 1400px) and (max-width: 1824px) {
  .section_title {
    @include heading2;
  }
  .services {
    flex-direction: row;
    gap: 4rem;
  }
  .home_hyperlink .home_hyperlink_text {
    @include hyperlinkM;
  }
  .latest_articles {
    // flex-direction: column;
    // gap: 3rem;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 4rem;
    row-gap: 4rem;
  }
}
@media (min-width: 1081px) and (max-width: 1400px) {
  .section_title {
    @include heading3;
  }
  .services {
    flex-direction: row;
    gap: 4rem;
  }
  .home_hyperlink .home_hyperlink_text {
    @include hyperlinkM;
  }
  .latest_articles {
    // flex-direction: column;
    // gap: 3rem;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 4rem;
    row-gap: 4rem;
  }
}
@media (min-width: 1024px) and (max-width: 1081px) {
  .section_title {
    @include heading4;
  }
  .services {
    flex-direction: column;
    gap: 4rem;
  }
  .grid_item {
    width: 20%;
  }
  .home_hyperlink .home_hyperlink_text {
    @include hyperlinkM;
  }
  .latest_articles {
    // flex-direction: column;
    // gap: 3rem;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 4rem;
    row-gap: 4rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .section_title {
    @include heading5;
  }
  .services {
    flex-direction: column;
    gap: 4rem;
  }
  .home_hyperlink .home_hyperlink_text {
    @include hyperlinkM;
  }
  .latest_articles {
    // flex-direction: column;
    // gap: 3rem;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 4rem;
    row-gap: 4rem;
  }
}
@media (min-width: 540px) and (max-width: 767px) {
  .section_title {
    @include heading5;
  }
  .services {
    flex-direction: column;
    gap: 4rem;
  }
  .latest_work {
    flex-direction: column;
    gap: 3rem;
  }
  .latest_articles {
    // flex-direction: column;
    // gap: 3rem;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 4rem;
    row-gap: 4rem;
  }
  .home_hyperlink .home_hyperlink_text {
    @include hyperlinkM;
  }
}
@media (min-width: 350px) and (max-width: 540px) {
  .section_title {
    @include heading5;
  }
  .services {
    flex-direction: column;
    gap: 4rem;
  }
  .latest_work {
    flex-direction: column;
    gap: 3rem;
  }
  .latest_articles {
    // flex-direction: column;
    // gap: 3rem;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 4rem;
    row-gap: 4rem;
  }
  .home_hyperlink .home_hyperlink_text {
    @include hyperlinkS;
  }
}
@media (max-width: 350px) {
  .section_title {
    @include bodyL;
    font-weight: 700;
  }
  .services {
    flex-direction: column;
    gap: 4rem;
  }
  .latest_work {
    flex-direction: column;
    gap: 3rem;
  }
  .latest_articles {
    // flex-direction: column;
    // gap: 3rem;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 4rem;
    row-gap: 4rem;
  }
  .home_hyperlink .home_hyperlink_text {
    @include hyperlinkS;
  }
}
// ----------------------------------- DARK MODE -------------------

.container {
  margin-top: 4rem;
  box-sizing: border-box;
  width: 100% !important;
  display: flex;
  flex-direction: column;
  gap: 5rem;
  align-items: center;
  margin-bottom: 8rem;
}

.section_title {
  // @include heading2;
  color: $white;
  align-self: flex-start;
}

.services_section {
  width: 90%;
  display: flex;
  flex-direction: column;
  // flex-grow: inherit;
  align-items: center;
  gap: 2rem;
}
.services {
  display: flex;
  width: 90%;
  // flex-direction: row;
  // flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  // gap: 4rem;
}

.work_section {
  width: 90%;
  display: flex;
  flex-direction: column;
  // flex-grow: inherit;
  align-items: center;
  gap: 2rem;
}
.latest_work {
  display: flex;
  width: 90%;
  // flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 3rem;
}
.btn_arrow {
  color: $pastel_violet;
  font-size: 70px;
  cursor: pointer;
}

.btn_arrow:hover {
  color: $copper;
  // font-size: 75px;
}

.blog_section {
  width: 90%;
  display: flex;
  flex-direction: column;
  // flex-grow: inherit;
  align-items: center;
  gap: 2rem;
}
.latest_articles {
  // display: flex;
  // width: 90%;
  // // flex-direction: row;
  // align-items: flex-start;
  // justify-content: space-between;
  // gap: 4rem;
  padding: 0.5rem;
  display: grid;
  //   grid-template-columns: repeat(2, 1fr);
  overflow: auto;
  //   column-gap: 4rem;
  //   row-gap: 4rem;
}
.card__btn {
  align-self: flex-start;
}
.contact_section {
  width: 90%;
  display: flex;
  flex-direction: column;
  // flex-grow: inherit;
  align-items: center;
  // gap: 2rem;
}

.footer_section {
  margin-top: 5rem;
  width: 100%;
  box-sizing: border-box;
}
.home_hyperlink {
  align-self: flex-start;
}
.home_hyperlink .home_hyperlink_text {
  // @include hyperlinkM;
  cursor: pointer;
}

.home_hyperlink .home_hyperlink_arrow {
  color: $pastel_violet;
  font-size: 20px;
  margin-left: 1rem;
  transition: 0.2s;
}

.home_hyperlink:hover .home_hyperlink_arrow,
.home_hyperlink:active .home_hyperlink_arrow {
  margin-left: 1.5rem;
}

// ----------------------------------- DARK MODE END -------------------

// ----------------------------------- LIGHT MODE -------------------

#light .section_title {
  color: $dark_charcoal;
}
#light .home_hyperlink {
  .home_hyperlink_text {
    color: $grape;
  }
  .home_hyperlink_arrow {
    color: $grape;
  }
  .home_hyperlink_text:hover {
    color: $copper;
  }
  .home_hyperlink_arrow:hover {
    color: $copper;
  }
}
#light .home_hyperlink:hover {
  .home_hyperlink_arrow {
    color: $copper;
  }
}
.back_to_top {
  background-color: $copper !important;
}

#light .back_to_top {
  background-color: $pastel_violet !important;
}
