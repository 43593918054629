@import "../../styles/style-guide.scss";

@media only screen and (min-width: 1824px) {
  .blog_gradient {
    width: 40%;
  }
  .blog_header h1 {
    @include mediumTitle;
  }
  .blog_header h4 {
    width: 70%;
    @include bodyL;
  }
  .blog_header p {
    width: 60%;
    @include bodyM;
  }
  .blog_posts {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 4rem;
    row-gap: 4rem;
  }
  .blog_header {
    margin-top: 3rem;
  }
  .subs_form input {
    width: 400px;
  }
  .subs_form {
    flex-direction: row;
  }
}
@media (min-width: 1400px) and (max-width: 1824px) {
  .blog_gradient {
    width: 45%;
  }
  .blog_header h1 {
    @include smallTitle;
  }
  .blog_header h4 {
    width: 80%;
    @include bodyM;
  }
  .blog_header p {
    width: 70%;
    @include bodyM;
  }
  .blog_posts {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 4rem;
    row-gap: 4rem;
  }
  .blog_header {
    margin-top: 3rem;
  }
  .subs_form input {
    width: 400px;
  }
  .subs_form {
    flex-direction: row;
  }
}
@media (min-width: 1081px) and (max-width: 1400px) {
  .blog_gradient {
    width: 50%;
  }
  .blog_header h1 {
    @include smallTitle;
  }
  .blog_header h4 {
    width: 80%;
    @include bodyM;
  }
  .blog_header p {
    width: 70%;
    @include bodyM;
  }
  .blog_posts {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 4rem;
    row-gap: 4rem;
  }
  .blog_header {
    margin-top: 3rem;
  }
  .subs_form input {
    width: 400px;
  }
  .subs_form {
    flex-direction: row;
  }
}
@media (min-width: 1024px) and (max-width: 1081px) {
  .blog_gradient {
    width: 70%;
  }
  .blog_header h1 {
    @include XsmallTitle;
  }
  .blog_header h4 {
    width: 90%;
    @include bodyM;
  }
  .blog_header p {
    width: 80%;
    @include bodyM;
  }
  .blog_posts {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 4rem;
    row-gap: 4rem;
  }
  .blog_header {
    margin-top: 2rem;
  }
  .subs_form input {
    width: 400px;
  }
  .subs_form {
    flex-direction: row;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .blog_gradient {
    width: 70%;
  }
  .blog_header h1 {
    @include XsmallTitle;
  }
  .blog_header h4 {
    width: 90%;
    @include bodyM;
  }
  .blog_header p {
    width: 80%;
    @include bodyM;
  }
  .blog_posts {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 3rem;
    row-gap: 3rem;
  }
  .blog_header {
    margin-top: 1rem;
  }
  .subs_form input {
    width: 400px;
  }
  .subs_form {
    flex-direction: row;
  }
}
@media (min-width: 540px) and (max-width: 767px) {
  .blog_gradient {
    width: 90%;
  }
  .blog_header h1 {
    @include heading1;
  }
  .blog_header h4 {
    width: 90%;
    @include bodyM;
  }
  .blog_header p {
    width: 90%;
    @include bodyM;
  }
  .blog_posts {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 3rem;
    row-gap: 3rem;
  }
  .blog_header {
    margin-top: 1rem;
  }
  .subs_form input {
    width: 300px;
  }
  .subs_form {
    flex-direction: row;
  }
}
@media (min-width: 350px) and (max-width: 540px) {
  .blog_gradient {
    width: 90%;
  }
  .blog_header h1 {
    @include heading1;
  }
  .blog_header h4 {
    width: 90%;
    @include bodyS;
    font-weight: 700;
  }
  .blog_header p {
    width: 90%;
    @include bodyS;
  }
  .blog_posts {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 3rem;
    row-gap: 3rem;
  }
  .blog_header {
    margin-top: 0;
  }
  .subs_form input {
    width: 300px;
  }
  .subs_form {
    flex-direction: column;
  }
}
@media (max-width: 350px) {
  .blog_gradient {
    width: 90%;
  }
  .blog_header h1 {
    @include heading1;
  }
  .blog_header h4 {
    width: 90%;
    @include bodyS;
    font-weight: 700;
  }
  .blog_header p {
    width: 90%;
    @include bodyS;
  }
  .blog_posts {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 3rem;
    row-gap: 3rem;
  }
  .blog_header {
    margin-top: 0;
  }
  .subs_form input {
    width: 200px;
  }
  .subs_form {
    flex-direction: column;
  }
}

.blog_gradient {
  //   width: 70%;
  position: absolute;
  top: 0;
  z-index: 1;
}
.blog_header {
  z-index: 2;
  width: 90%;
  //   margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 4rem;
}
.blog_header h1 {
  //   @include smallTitle;
  color: $white;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.blog_header h4 {
  //   width: 90%;
  //   @include bodyM;
  color: $white;
  text-align: center;
}
.blog_header p {
  //   width: 80%;
  //   @include bodyM;
  color: $white;
  text-align: center;
}
.subs_form {
  width: fit-content;
  background-color: $eerie_black;
  padding: 1rem;
  border-radius: 10px;
  display: flex;
  //   flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.subs_form input {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  @include bodyS;
  color: $white;
  display: flex;
  //   width: 400px;
  height: 46px;
  padding: 15px 21px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 8px;
  background: $dark_charcoal;
  border: none;
}
.subs_form input:focus {
  outline: none !important;
  border: 1px solid $copper;
  // box-shadow: 0 0 10px $grape;
}
.blog_posts {
  padding: 0.5rem;
  display: grid;
  //   grid-template-columns: repeat(2, 1fr);
  overflow: auto;
  //   column-gap: 4rem;
  //   row-gap: 4rem;
}

/*-------------- Light Mode -------------*/
#light .blog_gradient {
  display: none;
}
#light .blog_header h1 {
  color: $dark_charcoal;
  text-shadow: none;
}
#light .blog_header h4 {
  color: $dark_charcoal;
}

#light .blog_header p {
  color: $dark_charcoal;
}
#light .subs_form {
  background-color: $alabaster;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
    0px 1px 2px 0px rgba(0, 0, 0, 0.3);
}

#light .subs_form input {
  background-color: $white;
}
