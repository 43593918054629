@import "../../styles/style-guide.scss";

// ----------------------------------- DARK MODE -------------------
@media (max-width: 400px) {
  .blog_post__card {
    width: 95%;
  }
  .blog_post__title {
    @include bodyL;
    font-weight: 700;
  }
  .blog_post__date {
    @include bodyS;
  }
}
@media (min-width: 400px) and (max-width: 767px) {
  .blog_post__card {
    width: 390px;
  }
  .blog_post__title {
    @include heading5;
  }
  .blog_post__date {
    @include bodyS;
  }
}
@media (min-width: 767px) {
  .blog_post__card {
    width: 48%;
  }
  .blog_post__title {
    @include heading4;
  }
  .blog_post__date {
    @include bodyM;
  }
}
.blog_post__card {
  width: fit-content;

  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.blog_post__card:hover {
  .blog_post__img {
    border: 1px solid $copper;
  }
  .blog_post__title {
    color: $pastel_violet;
  }
}

.blog_post__img {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 22px;
  object-fit: cover;
  border: 1px solid $dark_charcoal;
}
.blog_post__title {
  // @include heading4;
  color: $white;
}
.blog_post__date {
  // @include bodyM;
  color: $white;
}
// ----------------------------------- DARK MODE END -------------------

// ----------------------------------- LIGHT MODE -------------------

#light .blog_post__card:hover {
  .blog_post__img {
    border: 0;
    box-shadow: 0px 1px 3px 1px $copper, 0px 1px 2px 0px $copper;
  }
  .blog_post__title {
    color: $copper;
  }
}

#light .blog_post__title {
  color: $dark_charcoal;
}
#light .blog_post__date {
  color: $dark_charcoal;
}
#light .blog_post__img {
  border: 0;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
    0px 1px 2px 0px rgba(0, 0, 0, 0.3);
}
