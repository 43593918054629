@import "../../styles/style-guide.scss";

@media only screen and (min-width: 1824px) {
  .gradient {
    width: 30%;
    position: absolute;
    top: 0;
    z-index: 1;
    // transition: all 300ms ease-in-out;
  }
}
@media (min-width: 1400px) and (max-width: 1824px) {
  .gradient {
    width: 50%;
    position: absolute;
    top: 0;
    z-index: 1;
    // transition: all 300ms ease-in-out;
  }
}
@media (min-width: 540px) and (max-width: 767px) {
  .gradient {
    width: 80%;
    position: absolute;
    top: 0;
    z-index: 1;
    // transition: all 300ms ease-in-out;
  }
}
.gradient {
  // width: 60%;
  position: absolute;
  top: 0;
  z-index: 1;
  // transition: all 300ms ease-in-out;
}

.resources_header {
  z-index: 2;
  width: 80%;
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  margin-bottom: 4rem;
}

.resources_header h1 {
  text-align: center;
  @include mediumTitle;
  color: $white;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.resources_header p {
  @include bodyL;
  text-align: center;
  color: $white;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

p a {
  font-weight: 800;
  color: $copper !important;
}

p a:hover {
  color: $pastel_violet !important;
}
.newsletter_section {
  width: 90%;
  display: flex;
  justify-content: center;
}

#light .resources_header h1 {
  color: $dark_charcoal;
}

#light .resources_header p {
  color: $dark_charcoal;
  text-shadow: none;
}
