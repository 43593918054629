// --------------------------COLORS---------------
// primary colors
$copper: #c26d37;
$grape: #6820a1;
// secondary colors
$pastel_violet: #c69bc6;

// neutral colors
$white: #ffffff;
$alabaster: #edece8;
$lotion: #fdfcf9;
$davy_grey: #5b5959;
$dark_charcoal: #323232;
$charleston_green: #282828;
$eerie_black: #1e1e1e;

// theme-variables.scss
$themes: (
  light: (
    colorHeaderFooter: #fff,
    colorText: #323232,
    colorBackground: #f8f8fa,
  ),
  dark: (
    colorHeaderFooter: #323232,
    colorText: #eff2f7,
    colorBackground: #222736,
  ),
);
@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;

      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }

      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}

// ----------------------------------TYPOGRAPHY-----------------

$fontFamily: "Work Sans", sans-serif;

@mixin bigTitle {
  /* Big Title LC */
  font-family: $fontFamily;
  font-size: 118px;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
  margin: 0;
  /* 150% */
}

@mixin mediumTitle {
  /* Medium Title */
  font-family: $fontFamily;
  font-size: 93px;
  font-style: normal;
  font-weight: 700;
  line-height: 100.5px;
  /* 150% */
  // text-transform: capitalize;
  margin: 0;
  /* Large screens ----------- */
}

@mixin smallTitle {
  /* Medium Title */
  font-family: $fontFamily;
  font-size: 73px;
  font-style: normal;
  font-weight: 700;
  line-height: 70.5px;
  /* 150% */
  // text-transform: capitalize;
  margin: 0;
  /* Large screens ----------- */
}

@mixin XsmallTitle {
  /* Medium Title */
  font-family: $fontFamily;
  font-size: 63px;
  font-style: normal;
  font-weight: 700;
  line-height: 60.5px;
  /* 150% */
  // text-transform: capitalize;
  margin: 0;
  /* Large screens ----------- */
}

@mixin heading1 {
  /* Heading 1 */
  font-family: $fontFamily;
  font-size: 44.7px;
  font-style: normal;
  font-weight: 700;
  line-height: 50.05px;
  margin: 0;
  /* 150% */
}

@mixin heading2 {
  /* Heading 2 */
  font-family: $fontFamily;
  font-size: 37px;
  font-style: normal;
  font-weight: 700;
  line-height: 55.5px;
  margin: 0;
  /* 150% */
}
@mixin heading3 {
  /* Heading 3 */
  font-family: $fontFamily;
  font-size: 31px;
  font-style: normal;
  font-weight: 700;
  line-height: 36.5px;
  margin: 0;
  /* 150% */
}

@mixin heading4 {
  /* Heading 4 */
  font-family: $fontFamily;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 39px;
  margin: 0;
  /* 150% */
}

@mixin heading5 {
  /* Heading 5 */
  font-family: $fontFamily;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  margin: 0;

  /* 150% */
  // text-transform: capitalize;
}
@mixin heading6 {
  /* Heading 5 */
  font-family: $fontFamily;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  margin: 0;

  /* 150% */
  // text-transform: capitalize;
}

@mixin bodyL {
  /* Body Large */
  font-family: $fontFamily;
  font-size: 21.6px;
  font-style: normal;
  // font-weight: 400;
  line-height: 32.4px;
  margin: 0;
  /* 150% */
}

@mixin bodyM {
  /* Body Medium */
  font-family: $fontFamily;
  font-size: 18px;
  font-style: normal;
  // font-weight: 400;
  line-height: 27px;
  margin: 0;
  /* 150% */
}

@mixin bodyS {
  /* Body Small */
  font-family: $fontFamily;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.5px;
  margin: 0;
  /* 150% */
}
@mixin button {
  /* Button Text */
  font-family: $fontFamily;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  margin: 0;
  /* 150% */
}
@mixin hyperlinkL {
  color: $pastel_violet;
  cursor: pointer;
  /* Hyperlink */
  font-family: $fontFamily;
  font-size: 24px;
  font-style: italic;
  font-weight: 600;
  line-height: 30px;
  margin: 0;
  /* 150% */
  text-decoration-line: underline;
}
@mixin hyperlinkM {
  color: $pastel_violet;
  cursor: pointer;
  /* Hyperlink */
  font-family: $fontFamily;
  font-size: 20px;
  font-style: italic;
  font-weight: 600;
  line-height: 30px;
  margin: 0;
  /* 150% */
  text-decoration-line: underline;
}
@mixin hyperlink {
  color: $pastel_violet;
  cursor: pointer;
  /* Hyperlink */
  font-family: $fontFamily;
  font-size: 20px;
  font-style: italic;
  font-weight: 600;
  line-height: 30px;
  margin: 0;
  /* 150% */
  text-decoration-line: underline;
}
@mixin hyperlinkS {
  color: $pastel_violet;
  cursor: pointer;
  /* Hyperlink */
  font-family: $fontFamily;
  font-size: 18px;
  font-style: italic;
  font-weight: 600;
  line-height: 30px;
  margin: 0;
  /* 150% */
  text-decoration-line: underline;
}
@mixin hyperlinkSS {
  color: $pastel_violet;
  cursor: pointer;
  /* Hyperlink */
  font-family: $fontFamily;
  font-size: 14px;
  font-style: italic;
  font-weight: 600;
  line-height: 30px;
  margin: 0;
  /* 150% */
  text-decoration-line: underline;
}
