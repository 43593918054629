@import "../../styles/style-guide.scss";

// ----------------------------------- DARK MODE -------------------
@media (max-width: 400px) {
  .grid__item {
    width: 95%;
  }
  .card__header {
    @include bodyL;
    font-weight: 700;
  }
  .card__text {
    @include bodyS;
  }
  .card__btn .card__btn_text {
    @include hyperlinkSS;
  }
}

@media (min-width: 400px) {
  .grid__item {
    width: 390px;
  }
  .card__header {
    @include heading4;
  }
  .card__text {
    @include bodyS;
  }
  .card__btn .card__btn_text {
    @include hyperlinkSS;
  }
}

.grid__item {
  // width: 390px;
  flex: auto;
  background-color: $eerie_black;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
    0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: 0.2s;
}

.grid__item:hover {
  .card__img1 {
    background-image: url("../../assets/images/service1_hovered.png");
  }
  .card__img2 {
    background-image: url("../../assets/images/service2_hover.png");
  }
  .card__img3 {
    background-image: url("../../assets/images/service3_hover.png");
  }
  .card__btn_text,
  .card__btn_arrow {
    color: $grape;
  }
  background-color: $copper;
  transform: translateY(-0.5%);
  box-shadow: 0 4rem 8rem rgba(0, 0, 0, 0.2);
}

.card__img1 {
  background-image: url("../../assets/images/service1.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  width: 100%;
  height: 248px;
  object-fit: cover;
}
.card__img2 {
  background-image: url("../../assets/images/service2.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  width: 100%;
  height: 248px;
  object-fit: cover;
}
.card__img3 {
  background-image: url("../../assets/images/service3.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  width: 100%;
  height: 248px;
  object-fit: cover;
}

.card__content {
  padding: 20px;
}
.card__header {
  // @include heading4;
  color: $white;
}

.card__text {
  // @include bodyM;
  color: $white;
  margin-top: 12px;
  margin-bottom: 12px;
}
.card__btn .card__btn_text {
  // @include hyperlinkS;
  cursor: pointer;
}

.card__btn .card__btn_arrow {
  color: $pastel_violet;
  font-size: 20px;
  margin-left: 1rem;
  transition: 0.2s;
}

.card__btn:hover .card__btn_arrow,
.card__btn:active .card__btn_arrow {
  margin-left: 1.5rem;
}
// ----------------------------------- DARK MODE END -------------------

// ----------------------------------- LIGHT MODE -------------------

#light .grid__item {
  background-color: $alabaster;
}
#light .card__header {
  color: $dark_charcoal;
}
#light .card__text {
  color: $dark_charcoal;
}
#light .grid__item:hover {
  background-color: $copper;
  .card__header,
  .card__text {
    color: $white;
  }
}
