@import "./styles/style-guide.scss";

// ----------------------------------- DARK MODE -------------------

.App {
  // padding: 3%;
  // width: 100vw;
  // height: 100vh;
  box-sizing: border-box;
  /* background-color: #282c34; */
}
#dark {
  background-color: $dark_charcoal;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// ----------------------------------- LIGHT MODE -------------------
#light {
  background-color: $lotion;
}

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background: $davy_grey;
  // border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: $copper;
  border-radius: 10px;
}
input:-webkit-autofill {
  border: none;
  -webkit-box-shadow: 0 0 0 50px $davy_grey inset; /* Change the color to your own background color */
  -webkit-text-fill-color: $white;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: /*your box-shadow*/ 0 0 0 50px $davy_grey inset;
  -webkit-text-fill-color: $white;
}

#light input:-webkit-autofill {
  border: none;
  -webkit-box-shadow: 0 0 0 50px $alabaster inset; /* Change the color to your own background color */
  -webkit-text-fill-color: $dark_charcoal;
}

#light input:-webkit-autofill:focus {
  -webkit-box-shadow: /*your box-shadow*/ 0 0 0 50px $alabaster inset;
  -webkit-text-fill-color: $dark_charcoal;
}
