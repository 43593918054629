@import "./styles/style-guide.scss";

body {
  // overflow-x: hidden;
  margin: 0;
  // background-color: $dark_charcoal;
  // padding: 3%;
  font-family: $fontFamily;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a:-webkit-any-link {
  text-decoration: none;
  color: white;
  cursor: pointer;
}
::-moz-selection {
  background: $copper;
}
::-webkit-selection {
  background: $copper;
}
::selection {
  background: $copper;
}

@keyframes loaderSwipeAnim {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
// #dark body{
//   background-color: $white;
// }

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }
