@import "../../styles/style-guide.scss";

.quote_container {
  display: inline-flex;
  align-items: center;
  gap: 15px;
}

.quote_line {
  width: 6px;
  min-width: 6px;
  align-self: stretch;
  background: $copper;
}

.quote_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.quote_content p {
  @include bodyS;
  color: $white;
  font-style: italic;
}
.quote_content span {
  color: $white;
  font-family: Work Sans;
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
  line-height: 22.5px; /* 187.5% */
}

#light .quote_content p {
  color: $dark_charcoal;
}
#light .quote_content span {
  color: $dark_charcoal;
}
