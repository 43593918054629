@import "../../styles/style-guide.scss";

// ----------------------------------- DARK MODE -------------------
@media only screen and (min-width: 1824px) {
  /* Styles */
  .bio h3 {
    @include heading2;
  }
  .bio {
    gap: 1rem;
  }
  .bio h1 {
    @include bigTitle;
  }
  .role span {
    @include heading1;
  }
  .role .role_name {
    @include heading1;
  }
  .bio p {
    width: 760px;
    @include heading4;
    font-weight: 400;
  }
  .call_to_action span {
    @include hyperlinkL;
  }
  .profile_img {
    width: 377px;
    // height: 497px;
    border-radius: 126px;
  }
  .FindMeHere {
    @include heading2;
  }
  .bio_img {
    flex-direction: row;
  }
  .social_icon {
    font-size: 5rem;
  }
  .social-media {
    border-radius: 16px;
    padding: 38px;
    gap: 3rem;
  }
}
/* ------------- Desktop screens ------------*/
@media (min-width: 1400px) and (max-width: 1824px) {
  .bio h3 {
    @include heading3;
  }
  .bio {
    gap: 1rem;
  }
  .bio h1 {
    @include mediumTitle;
  }
  .role span {
    @include heading2;
  }
  .role .role_name {
    @include heading2;
  }
  .bio p {
    width: 760px;
    @include bodyL;
  }
  .call_to_action span {
    @include hyperlinkM;
  }
  .profile_img {
    width: 377px;
    // height: 497px;
    border-radius: 126px;
  }
  .FindMeHere {
    @include heading3;
  }
  .bio_img {
    flex-direction: row;
  }
  .social_icon {
    font-size: 5rem;
  }
  .social-media {
    border-radius: 16px;
    padding: 38px;
    gap: 3rem;
  }
}
/* ------------- Laptop screens ------------*/
@media (min-width: 1081px) and (max-width: 1400px) {
  .bio h3 {
    @include heading4;
  }
  .bio {
    gap: 1rem;
  }
  .bio h1 {
    @include smallTitle;
  }
  .role span {
    @include heading4;
  }
  .role .role_name {
    @include heading4;
  }
  .bio p {
    width: 600px;

    @include bodyM;
  }
  .call_to_action span {
    @include hyperlinkS;
  }
  .profile_img {
    width: 320px;
    // order: 2;
    // height: 497px;
    border-radius: 116px;
  }
  .FindMeHere {
    @include heading4;
  }
  // .bio_img {
  //   flex-direction: row;
  // }
  .social-media span {
    width: 100px;
    height: 100px;
  }
  .social_icon {
    font-size: 5rem;
  }
  .social-media {
    border-radius: 16px;
    padding: 38px;
    gap: 3rem;
  }
}

/*-------------- Small Laptop screens ------------*/
@media (min-width: 1024px) and (max-width: 1081px) {
  .bio h3 {
    @include heading4;
  }
  .bio {
    gap: 1rem;
  }
  .bio h1 {
    @include smallTitle;
  }
  .role span {
    @include heading5;
  }
  .role .role_name {
    @include heading5;
  }
  .bio p {
    width: 500px;

    @include bodyM;
  }
  .call_to_action span {
    @include hyperlinkS;
  }
  .profile_img {
    width: 280px;
    // order: 2;
    // height: 497px;
    border-radius: 116px;
  }
  .FindMeHere {
    @include heading5;
  }
  // .bio_img {
  //   flex-direction: row;
  // }
  .social-media span {
    width: 100px;
    height: 100px;
  }
  .social_icon {
    font-size: 3rem;
  }
  .social-media {
    border-radius: 16px;
    padding: 38px;
    gap: 3rem;
  }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
  .bio h3 {
    padding: 0;
    @include heading5;
  }
  .bio {
    gap: 1rem;
  }
  .bio h1 {
    @include XsmallTitle;
  }
  .role span {
    @include heading5;
    font-weight: 700;
  }
  .role .role_name {
    @include heading5;
  }
  .bio p {
    width: 500px;

    @include bodyM;
  }
  .call_to_action span {
    @include hyperlinkS;
  }
  .profile_img {
    width: 250px;
    // height: 497px;
    border-radius: 100px;
  }
  .FindMeHere {
    @include heading5;
  }
  .bio_img {
    flex-direction: column;
  }
  .social-media span {
    width: 50px;
    height: 50px;
  }
  .social_icon {
    font-size: 3rem;
  }
  .social-media {
    border-radius: 16px;
    padding: 38px;
    gap: 3rem;
  }
}
/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 540px) and (max-width: 767px) {
  .bio h3 {
    padding: 0;
    @include heading5;
  }
  .bio {
    gap: 1rem;
  }
  .bio h1 {
    @include XsmallTitle;
  }
  .role span {
    @include heading5;
    font-weight: 700;
  }
  .role .role_name {
    @include heading5;
  }
  .bio p {
    width: 500px;

    @include bodyM;
  }
  .call_to_action span {
    @include hyperlinkS;
  }
  .profile_img {
    width: 250px;
    // height: 497px;
    border-radius: 100px;
  }
  .FindMeHere {
    @include bodyM;
  }
  .bio_img {
    flex-direction: column;
  }
  .social-media span {
    width: 50px;
    height: 50px;
  }
  .social_icon {
    font-size: 2rem;
  }
  .social-media {
    border-radius: 16px;
    padding: 28px;
    gap: 1.5rem;
  }
}

/* -----------Media Query for devices smartphones*/
@media (min-width: 350px) and (max-width: 540px) {
  .bio h3 {
    padding: 0;
    @include bodyL;
  }
  .bio {
    gap: 0.5rem;
  }
  .bio h1 {
    @include heading1;
  }
  .role span {
    @include bodyM;
    font-weight: 700;
  }
  .role .role_name {
    @include bodyM;
  }
  .bio p {
    // width: 300px;

    @include bodyS;
  }
  .call_to_action span {
    @include hyperlinkS;
  }
  .profile_img {
    width: 250px;
    // height: 497px;
    border-radius: 100px;
  }
  .FindMeHere {
    @include bodyS;
  }
  .bio_img {
    flex-direction: column;
  }

  .social_icon {
    font-size: 1.5rem;
  }
  .social-media {
    border-radius: 10px;
    padding: 20px;
    gap: 1rem;
  }
}

/* ----------Media Query for low resolution  small devices */
@media (max-width: 350px) {
  .bio h3 {
    padding: 0;
    @include bodyM;
  }
  .bio {
    gap: 0.5rem;
  }
  .bio h1 {
    @include heading2;
  }
  .role span {
    @include bodyM;
    font-weight: 700;
  }
  .role .role_name {
    @include bodyM;
  }
  .bio p {
    // width: 300px;

    @include bodyS;
  }
  .call_to_action span {
    @include hyperlinkS;
  }
  .profile_img {
    width: 90%;
    // height: 497px;
    border-radius: 100px;
  }
  .FindMeHere {
    @include bodyS;
  }
  .bio_img {
    flex-direction: column;
  }

  .social_icon {
    font-size: 1rem;
  }
  .social-media {
    border-radius: 8px;
    padding: 15px;
    gap: 10px;
  }
}

.bio-section {
  // padding: 0 4% 0 4%;

  // padding-top: 5%;
  padding-left: 2%;
  padding-right: 2%;
  box-sizing: border-box;
  width: 95%;
  display: flex;
  gap: 15px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.bio_img {
  // width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  // flex-wrap: wrap;
}
.profile_img {
  order: 2;
  box-shadow: 0 4rem 8rem rgba(0, 0, 0, 0.2);
}
.bio {
  order: 1;
  // width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.bio h3 {
  // @include heading3;
  color: $white;
}
.bio h1 {
  text-transform: uppercase;
  // @include mediumTitle;
  // line-height: 90px;
  color: $white;
}
// .role {
//   position: relative;
// }

.role span {
  color: $copper;
  // @include heading2;
}

.role .role_name {
  color: $white;
  // @include heading2;
}

.bio p {
  // width: 760px;
  color: $white;
  // @include bodyL;
}
.call_to_action span:hover {
  // @include hyperlink;
  color: $copper;
}

.social-media {
  // z-index: 99;

  background-color: $charleston_green;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
    0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  display: flex;
  width: fit-content;
  justify-content: space-between;
  align-items: center;
}
.FindMeHere {
  // @include heading3;
  color: $white;
}

.social_icon {
  color: $pastel_violet;
  // font-size: 5rem;
}
.social_icon:hover {
  color: $copper;
}
// ----------------------------------- DARK MODE END -------------------

// ----------------------------------- LIGHT MODE -------------------

#light .bio h3 {
  color: $dark_charcoal;
}

#light .bio h1 {
  color: $dark_charcoal;
}

#light .role_name {
  color: $dark_charcoal;
}

#light .bio p {
  color: $dark_charcoal;
}

#light .call_to_action span {
  color: $grape;
}
#light .call_to_action span:hover {
  color: $copper;
}
#light .social_icon {
  color: $copper;
}
#light .social_icon:hover {
  color: $grape;
}
// .profile_img {

//   // flex: 0 0 0;
//   // width: 30%; /* Float the div to the right */
// }

#light .social-media {
  background-color: $alabaster;
}

#light .FindMeHere {
  color: $dark_charcoal;
}
