@import "../../styles/style-guide.scss";

.tab_btn {
  display: flex;
  flex-direction: row;
  //   height: 51px;
  padding: 8px 2rem;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: $davy_grey;
  height: fit-content;
  @include bodyS;
  cursor: pointer;
  outline: none;
  border: 0;
  color: $white;
  //   @include button;

  //   background: $copper;
}
.tab_btn:hover {
  background-color: $grape;
}

.tab_btn:focus {
  background-color: $copper;
}
.tab_btn.active {
  background-color: $copper;
}
