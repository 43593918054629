@import "../../styles/style-guide.scss";

@media only screen and (min-width: 1824px) {
  .gradient {
    top: 0;
    width: 50%;
  }
  .contact_header {
    margin-top: 3rem;
    width: 60%;
  }
  .contact_header h1 {
    @include mediumTitle;
  }
  .form_section {
    width: 50%;
  }
  .contact_header p {
    @include bodyL;
  }
  .two_fields {
    flex-direction: row;
    gap: 1rem;
  }
  .two_fields .field {
    width: 45%;
  }
}
@media (min-width: 1400px) and (max-width: 1824px) {
  .gradient {
    top: 0;
    width: 50%;
  }
  .contact_header {
    margin-top: 3rem;
    width: 70%;
  }
  .contact_header h1 {
    @include mediumTitle;
  }
  .form_section {
    width: 50%;
  }
  .contact_header p {
    @include bodyL;
  }
  .two_fields {
    flex-direction: row;
    gap: 1rem;
  }
  .two_fields .field {
    width: 45%;
  }
}
@media (min-width: 1081px) and (max-width: 1400px) {
  .gradient {
    top: 0;
    width: 60%;
  }
  .contact_header {
    margin-top: 3rem;
    width: 80%;
  }
  .contact_header h1 {
    @include mediumTitle;
  }
  .form_section {
    width: 70%;
  }
  .contact_header p {
    @include bodyL;
  }
  .two_fields {
    flex-direction: row;
    gap: 1rem;
  }
  .two_fields .field {
    width: 45%;
  }
}
@media (min-width: 1024px) and (max-width: 1081px) {
  .gradient {
    top: 0;
    width: 60%;
  }
  .contact_header {
    margin-top: 3rem;
    width: 80%;
  }
  .contact_header h1 {
    @include smallTitle;
  }
  .form_section {
    width: 70%;
  }
  .contact_header p {
    @include bodyL;
  }
  .two_fields {
    flex-direction: row;
    gap: 1rem;
  }
  .two_fields .field {
    width: 45%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .gradient {
    top: 1rem;
    width: 80%;
  }
  .contact_header {
    margin-top: 2rem;
    width: 90%;
  }
  .contact_header h1 {
    @include smallTitle;
  }
  .form_section {
    width: 70%;
  }
  .contact_header p {
    @include bodyM;
  }
  .two_fields {
    flex-direction: row;
    gap: 1rem;
  }
  .two_fields .field {
    width: 45%;
  }
}
@media (min-width: 540px) and (max-width: 767px) {
  .gradient {
    top: 1rem;
    width: 80%;
  }
  .contact_header {
    margin-top: 2rem;
    width: 90%;
  }
  .contact_header h1 {
    @include XsmallTitle;
  }
  .form_section {
    width: 70%;
  }
  .contact_header p {
    @include bodyM;
  }
  .two_fields {
    flex-direction: column;
    gap: 3rem;
  }
  .two_fields .field {
    width: 100%;
  }
}
@media (min-width: 350px) and (max-width: 540px) {
  .gradient {
    top: 1rem;
    width: 100%;
  }
  .contact_header {
    margin-top: 1rem;
    width: 85%;
  }
  .contact_header h1 {
    @include heading1;
  }
  .form_section {
    width: 80%;
  }
  .contact_header p {
    @include bodyS;
  }
  .two_fields {
    flex-direction: column;
    gap: 3rem;
  }
  .two_fields .field {
    width: 100%;
  }
}
@media (max-width: 350px) {
  .gradient {
    top: 1rem;

    width: 100%;
  }
  .contact_header {
    margin-top: 0;
    width: 85%;
  }
  .contact_header h1 {
    @include heading1;
  }
  .form_section {
    width: 80%;
  }
  .contact_header p {
    @include bodyS;
  }
  .two_fields {
    flex-direction: column;
    gap: 3rem;
  }
  .two_fields .field {
    width: 100%;
  }
}
.gradient {
  // width: 60%;
  position: absolute;
  // top: 0;
  z-index: 1;
  // transition: all 300ms ease-in-out;
}

.contact_header {
  z-index: 2;
  // width: 80%;
  // margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  margin-bottom: 4rem;
}
.contact_header h1 {
  text-align: center;
  // @include mediumTitle;
  color: $white;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.contact_header p {
  // @include bodyL;
  text-align: center;
  color: $white;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.contact_header p a {
  color: $copper;
  font-weight: 700;
}
.form_section {
  z-index: 2;

  box-sizing: border-box;
  // width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
}
.two_fields {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  // flex-direction: row;
  justify-content: space-between;
  // gap: 1rem;
}

.field {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}
.field p {
  @include bodyM;
  color: $white;
}

.field input {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  @include bodyS;
  color: $white;
  display: flex;
  width: 100%;
  height: 56px;
  padding: 15px 21px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 8px;
  background: $davy_grey;
  border: none;
}

.field input:focus {
  outline: none !important;
  border: 1px solid $copper;
  // box-shadow: 0 0 10px $grape;
}

::placeholder {
  @include bodyS;
  color: #8e8d8d;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
.dollar {
  position: relative;
  left: -50px;
}

.field select option[default] {
  color: #888;
}

.field textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-color: $davy_grey;
  border: 0;
  border-radius: 8px;
  width: 100%;
  padding: 10px 21px;
  color: $white;
}
.field textarea:focus {
  outline: none !important;
  border: 1px solid $copper;
}
.select_field {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}
.select_field p {
  @include bodyM;
  color: $white;
}
.send_btn {
  align-self: flex-end;
  right: 0;
}

/*------------ Light mode -----------*/
#light .select_field p {
  color: $dark_charcoal;
}
#light .contact_header h1 {
  color: $dark_charcoal;
  text-shadow: none;
}
#light .contact_header p {
  color: $dark_charcoal;
  text-shadow: none;
}
#light .field p {
  color: $dark_charcoal;
}

#light .field input {
  background-color: $alabaster;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
    0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  color: $dark_charcoal;
}
#light .field input:focus {
  outline: none !important;
  border: 0;
  box-shadow: 0px 1px 3px 1px $copper, 0px 1px 2px 0px $copper;
  // box-shadow: 0 0 10px $grape;
}
#light .field textarea {
  background-color: $alabaster;
  color: $dark_charcoal;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
    0px 1px 2px 0px rgba(0, 0, 0, 0.3);
}
#light .field textarea:focus {
  // outline: none !important;
  border: none;
  box-shadow: 0px 1px 3px 1px $copper, 0px 1px 2px 0px $copper;
}
.error {
  color: rgb(193, 117, 117);
}
