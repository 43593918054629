@import "../../styles/style-guide.scss";

// ----------------------------------- DARK MODE -------------------

header {
  position: relative;
  padding: 4% 4% 0 4%;
  width: 100%;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  z-index: 10;
  justify-content: space-between;
  @include themify($themes) {
    background: themed("headerFooterBackground");
  }
}

header .purple_logo {
  display: block;
  height: 51px;
  object-fit: cover;
  position: relative;
  width: 25px;
}
.nav_menu {
  display: flex;
  padding: 7px;
  background: transparent;
  justify-content: flex-end;
  align-items: center;
  gap: 35px;
}

nav .navLink {
  @include bodyL;
  cursor: pointer;
  text-decoration: none !important;
  // text-decoration-line: none;
}

nav .navLink:hover {
  color: $copper;
}

.menu_icon {
  color: $white;
  display: none;
}

nav .navLinkactive {
  @include bodyL;
  color: $copper;
  cursor: pointer;
  text-decoration: none !important;
}

// ----------------------------------- DARK MODE END -------------------

// ----------------------------------- LIGHT MODE -------------------
#dark .navLink {
  color: $white;
}
#dark .navLink:hover {
  color: $copper;
}
#light .navLink {
  color: $dark_charcoal;
}
#light .navLink:hover {
  color: $copper;
}
#light .menu_icon {
  color: $copper;
}

// ----------------------------------- RESPONSIVENESS -------------------

@media (max-width: 930px) {
  header {
    position: relative;
  }
  .menu_icon {
    display: block;
    cursor: pointer;
  }
  #light .nav_menu {
    background-color: $lotion;
  }
  .nav_menu {
    // z-index: 22;

    position: absolute;
    flex-direction: column;
    background-color: $dark_charcoal;
    top: 5rem;
    // right: 4rem;
    left: -100%;
    // transform: translate(-150%);
    // left: -100%;
    padding: 2rem;
    border-radius: 12px;
    transition: all 300ms ease-in-out;
  }
  .nav_menu.active {
    // position: absolute;
    left: 0;
    right: 0;
    top: 5rem;
    opacity: 1;
    margin-bottom: 2rem;
    z-index: 5;
  }
}
