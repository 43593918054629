@import "../../styles/style-guide.scss";

.post_container {
  width: 90%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 3rem;
}

.post_content {
  flex: 1;
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.post_date {
  @include bodyM;
  color: $white;
}
#light .post_title {
  color: $dark_charcoal;
}
#light .post_date {
  color: $dark_charcoal;
}
#light .post_read_time {
  color: $dark_charcoal;
}
#light .post_category {
  color: $dark_charcoal;
}
.post_title {
  @include heading2;
  color: $white;
}

.post_cover {
  border-radius: 20px;
}
.post_read_time {
  @include bodyS;
  color: $alabaster;
}
.post_category {
  @include bodyM;
  color: $alabaster;
}

.post_category span {
  font-weight: 700;
  font-style: italic;
}
#light .post_category span {
  color: $dark_charcoal;
}
.post_category span:hover {
  cursor: pointer;
  color: $copper;
}
#light .post_divider {
  border-color: $alabaster;
}
.post_divider {
  border: none; /* Remove default border */
  border-top: 2px solid $davy_grey; /* Add a 1px solid border with your desired color */
  margin: 10px 0; /* Adjust margin as needed */
}
.post_body :is(img) {
  width: 80%;
  height: auto;
  margin-left: 3rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 16px;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
    0px 1px 2px 0px rgba(0, 0, 0, 0.3);
}

.post_body h2 {
  @include heading4;
  color: $white;
  line-height: 60px;
}
#light .post_body h2 {
  color: $dark_charcoal;
}
#light .post_body h3 {
  color: $dark_charcoal;
}
#light .post_body h4 {
  color: $dark_charcoal;
}
#light .post_body blockquote {
  color: $dark_charcoal;
}
#light .post_body p {
  color: $dark_charcoal;
}
#light .post_body a {
  color: $grape;
}
.post_body h3 {
  @include heading5;
  color: $white;
  line-height: 60px;
}

.post_body h4 {
  @include heading6;
  color: $white;
  line-height: 50px;
}

.post_body blockquote {
  margin: 2rem !important;
  border-left: 0.5rem solid $copper;
  padding: 0.5rem;
  width: 75%;
  color: $alabaster;
  @include bodyS;
  font-style: italic;
}

.post_body p {
  margin-top: 1rem !important;
  margin-bottom: 2rem !important;
  @include bodyM;
  color: $white;
}

.post_body a {
  color: $pastel_violet;
  font-weight: 700;
}
.author_box {
  flex: 2;
  width: 100%;

  right: 0;
}
.categs {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
#light .categs h4 {
  color: $dark_charcoal;
}
#light .categs p {
  color: $dark_charcoal;
}
#light .categs p:hover {
  color: $copper;
}
.categs h4 {
  @include heading4;
  color: $white;
}

.categs p {
  @include bodyM;
  color: $white;
}
.categs p:hover {
  color: $copper;
}
