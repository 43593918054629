@import "../../styles/style-guide.scss";

.main_btn {
  cursor: pointer;
  outline: none;
  border: 0;
  color: $white;
  @include button;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  padding: 6px 23px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 7px;
  background: $copper;
}
.main_btn span {
  display: flex;
  align-items: center;
  gap: 10px;
}
.main_btn:hover {
  background-color: $grape;
  transition: 0.3s;
}
.main_btn:disabled {
  height: 42px;
  width: 226px;
  background: #b3b3b3;
  color: $davy_grey;
  cursor: not-allowed;
}
.arrow_icon {
  font-size: 30px;
}

.spinner {
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
