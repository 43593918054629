@import "../../styles/style-guide.scss";

@media only screen and (min-width: 1824px) {
  .portf_gradient {
    width: 80%;
  }
  .portfolio_header {
    margin-top: 4rem;
    gap: 9rem;
    margin-bottom: 4rem;
  }
  .quote1 {
    @include mediumTitle;
  }
  .quote2 {
    @include mediumTitle;
    line-height: 120px;
  }
  .header_text h4 {
    @include heading3;
  }
  .header_text p {
    @include bodyL;
  }
  .header_text {
    width: 70%;
  }
  .principles {
    flex-direction: row;
    align-items: flex-start;
  }
  .principle img {
    width: 80%;
  }
  .principle h4 {
    @include heading5;
  }
  .principle p {
    @include bodyM;
  }
  .principle {
    width: 28%;
  }
  .projects {
    grid-template-columns: repeat(4, 1fr);
    column-gap: 2rem;
    row-gap: 2rem;
  }
  .quote {
    gap: 2rem;
  }
}
@media (min-width: 1400px) and (max-width: 1824px) {
  .portf_gradient {
    width: 60%;
  }
  .portfolio_header {
    margin-top: 4rem;
    gap: 9rem;
    margin-bottom: 4rem;
  }
  .quote1 {
    @include smallTitle;
  }
  .quote2 {
    @include smallTitle;
  }
  .header_text h4 {
    @include heading4;
  }
  .header_text p {
    @include bodyM;
  }
  .header_text {
    width: 80%;
  }
  .principles {
    flex-direction: row;
    align-items: flex-start;
  }
  .principle img {
    width: 70%;
  }
  .principle h4 {
    @include heading5;
  }
  .principle p {
    @include bodyM;
  }
  .principle {
    width: 28%;
  }
  .projects {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 2rem;
    row-gap: 2rem;
  }
  .quote {
    gap: 2rem;
  }
}
@media (min-width: 1081px) and (max-width: 1400px) {
  .portf_gradient {
    width: 80%;
  }
  .portfolio_header {
    margin-top: 4rem;
    gap: 5rem;
    margin-bottom: 4rem;
  }
  .quote1 {
    @include smallTitle;
  }
  .quote2 {
    @include smallTitle;
  }
  .header_text h4 {
    @include heading4;
  }
  .header_text p {
    @include bodyM;
  }
  .header_text {
    width: 90%;
  }
  .principles {
    flex-direction: row;
    align-items: flex-start;
  }
  .principle img {
    width: 70%;
  }
  .principle h4 {
    @include heading5;
  }
  .principle p {
    @include bodyM;
  }
  .principle {
    width: 28%;
  }
  .projects {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 2rem;
    row-gap: 2rem;
  }
  .quote {
    gap: 2rem;
  }
}
@media (min-width: 1024px) and (max-width: 1081px) {
  .portf_gradient {
    width: 90%;
  }
  .portfolio_header {
    margin-top: 4rem;
    gap: 5rem;
    margin-bottom: 4rem;
  }
  .quote1 {
    @include XsmallTitle;
  }
  .quote2 {
    @include XsmallTitle;
  }
  .header_text h4 {
    @include heading4;
  }
  .header_text p {
    @include bodyM;
  }
  .header_text {
    width: 90%;
  }
  .principles {
    flex-direction: row;
    align-items: flex-start;
  }
  .principle img {
    width: 70%;
  }
  .principle h4 {
    @include heading5;
  }
  .principle p {
    @include bodyM;
  }
  .principle {
    width: 28%;
  }
  .projects {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
    row-gap: 2rem;
  }
  .quote {
    gap: 2rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .portf_gradient {
    width: 98%;
  }
  .portfolio_header {
    margin-top: 4rem;
    gap: 5rem;
    margin-bottom: 4rem;
  }
  .quote1 {
    @include XsmallTitle;
  }
  .quote2 {
    @include XsmallTitle;
  }
  .header_text h4 {
    @include heading4;
  }
  .header_text p {
    @include bodyM;
  }
  .header_text {
    width: 90%;
  }
  .principles {
    flex-direction: row;
    align-items: flex-start;
  }
  .principle img {
    width: 70%;
  }
  .principle h4 {
    @include bodyL;
    font-weight: 700;
  }
  .principle p {
    @include bodyS;
  }
  .principle {
    width: 28%;
  }
  .projects {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
    row-gap: 2rem;
  }
  .quote {
    gap: 2rem;
  }
}
@media (min-width: 540px) and (max-width: 767px) {
  .portf_gradient {
    width: 100%;
    top: 6rem;
  }
  .portfolio_header {
    margin-top: 1rem;
    gap: 3rem;
    margin-bottom: 4rem;
  }
  .quote1 {
    @include heading1;
  }
  .quote2 {
    @include heading1;
  }
  .header_text h4 {
    @include heading5;
  }
  .header_text p {
    @include bodyS;
  }
  .header_text {
    width: 90%;
  }
  .principles {
    flex-direction: column;
    align-items: center;
  }
  .principle {
    width: 80%;
  }
  .principle img {
    width: 50%;
  }
  .principle h4 {
    @include bodyL;
    font-weight: 700;
  }
  .principle p {
    @include bodyS;
  }
  .projects {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 2rem;
    row-gap: 2rem;
  }
  .quote {
    gap: 1rem;
  }
}
@media (min-width: 350px) and (max-width: 540px) {
  .portf_gradient {
    width: 100%;
    top: 7rem;
  }
  .portfolio_header {
    // margin-top: 1rem;
    gap: 2rem;
    margin-bottom: 4rem;
  }
  .quote1 {
    @include heading3;
  }
  .quote2 {
    @include heading3;
  }
  .header_text h4 {
    @include bodyM;
  }
  .header_text p {
    @include bodyS;
  }
  .header_text {
    width: 90%;
  }
  .principles {
    flex-direction: column;
    align-items: center;
  }
  .principle {
    width: 80%;
  }
  .principle img {
    width: 50%;
  }
  .principle h4 {
    @include bodyL;
    font-weight: 700;
  }
  .principle p {
    @include bodyS;
  }
  .projects {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 2rem;
    row-gap: 2rem;
  }
  .quote {
    gap: 0.5rem;
  }
}
@media (max-width: 350px) {
  .portf_gradient {
    width: 100%;
    top: 7rem;
  }
  .portfolio_header {
    // margin-top: 1rem;
    gap: 2rem;
    margin-bottom: 4rem;
  }
  .quote1 {
    @include heading3;
  }
  .quote2 {
    @include heading3;
  }
  .header_text h4 {
    @include bodyM;
  }
  .header_text p {
    @include bodyS;
  }
  .header_text {
    width: 90%;
  }
  .principles {
    flex-direction: column;
    align-items: center;
  }
  .principle {
    width: 80%;
  }
  .principle img {
    width: 50%;
  }
  .principle h4 {
    @include bodyL;
    font-weight: 700;
  }
  .principle p {
    @include bodyS;
  }
  .projects {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 2rem;
    row-gap: 2rem;
  }
  .quote {
    gap: 0.5rem;
  }
}

.portf_gradient {
  //   width: 80%;
  position: absolute;
  top: 0;
  z-index: 1;
  // transition: all 300ms ease-in-out;
}

.portfolio_header {
  z-index: 2;
  width: 90%;
  //   margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //   gap: 9rem;
  //   margin-bottom: 4rem;
}
.quote {
  display: flex;
  flex-direction: column;
  // gap: 2rem;
}
.quote1 {
  //   @include smallTitle;
  left: -100%;
  animation: slide-right 1s ease-in;
  color: $white;
  text-align: center;
  text-shadow: 0px 7px 4px rgba(0, 0, 0, 0.25);
  font-style: italic;
}

.quote2 {
  //   @include smallTitle;
  left: -100%;
  animation: slide-left 1s ease-in;
  color: $white;
  text-align: center;
  text-shadow: 0px 7px 4px rgba(0, 0, 0, 0.25);
  font-style: italic;
}
.header_text {
  //   width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.header_text h4 {
  //   @include heading4;
  color: $white;
  text-align: center;
}
.header_text p {
  @include bodyM;
  color: $white;
  text-align: center;
}

.dp_section {
  width: 90%;
  display: flex;
  flex-direction: column;
  // flex-grow: inherit;
  align-items: center;
  gap: 2rem;
}

.principles {
  width: 100%;
  display: flex;
  //   flex-direction: row;
  //   align-items: flex-start;
  justify-content: space-between;
}
.principle {
  //   width: 28%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
}

.principle h4 {
  //   @include heading5;
  color: $white;
}
.principle p {
  //   @include bodyM;
  color: $white;
}

.projects_section {
  width: 90%;
  display: flex;
  flex-direction: column;
  // flex-grow: inherit;
  align-items: flex-start;
  margin-bottom: 3rem;
  gap: 2.5rem;
}
.tabs {
  align-self: flex-start;
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 2rem;
}
.tab {
  display: flex;
  flex-direction: row;
  //   height: 51px;
  padding: 8px 2rem;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: $davy_grey;
}
// .active {
//   background-color: $copper;
// }
.tab span {
  @include bodyS;
  color: $white;
}
.projects {
  padding: 1rem;
  display: grid;
  // grid-template-columns: repeat(3, 1fr);
  overflow: auto;
  // column-gap: 2rem;
  // row-gap: 2rem;
}
.project_card {
  width: 100%;
}
/*-------- Light mode -------------*/
#light .quote1 {
  color: $dark_charcoal;
  // background: -webkit-linear-gradient($copper, $grape);
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
  // text-shadow: none;
  // // line-height: 82px;

  //   text-shadow: 0px 7px 4px $dark_charcoal;
}
#light .quote2 {
  color: $dark_charcoal;
  // background: -webkit-linear-gradient($grape, $copper);
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
  // text-shadow: none;
  // // line-height: 82px;
}
#light .portf_gradient {
  display: none;
}
#light .header_text h4 {
  color: $dark_charcoal;
}
#light .header_text p {
  color: $dark_charcoal;
}
#light .principle h4 {
  color: $dark_charcoal;
}
#light .principle p {
  color: $dark_charcoal;
}
/*------- animations -----------*/
@keyframes slide-left {
  from {
    margin-right: -100%;
  }
  to {
    margin-right: 0;
  }
}
@keyframes slide-right {
  from {
    margin-left: -100%;
  }
  to {
    margin-left: 0;
  }
}
