@import "../../styles/style-guide.scss";

// ----------------------------------- DARK MODE -------------------
@media (max-width: 400px) {
  .proj__card {
    padding: 12px;
    width: 95%;
    border-radius: 14px;
  }
  .proj__title {
    @include bodyM;
    // font-weight: 700;
  }
  .proj__img {
    border-radius: 12px;
  }
}

@media (min-width: 400px) and (max-width: 767px) {
  .proj__card {
    padding: 14px;
    width: 390px;
    border-radius: 18px;
  }
  .proj__title {
    @include bodyM;
  }
  .proj__img {
    border-radius: 14px;
  }
}

@media (min-width: 767px) {
  .proj__card {
    padding: 18px;
    width: 48%;
    border-radius: 22px;
  }
  .proj__title {
    @include bodyM;
  }
  .proj__img {
    border-radius: 18px;
  }
}

.proj__card {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  box-sizing: border-box;
  background: $eerie_black;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
    0px 1px 2px 0px rgba(0, 0, 0, 0.3);
}

.proj__card:hover {
  // background-color: $grape;
  transform: translateY(-0.5%);
  border: solid $grape 1px;
  // box-shadow: 0 4rem 8rem rgba(0, 0, 0, 0.2);
  .proj__category {
    background-color: $copper;
  }
  .proj__title {
    color: $white;
  }
}
.proj__img {
  display: block;

  width: 100%;
  // height: 308px;
  object-fit: cover;
}

.proj__category {
  width: fit-content;
  display: flex;
  padding: 5px 14px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 14px;
  background: $davy_grey;
}

.proj__category p {
  @include bodyS;
  color: $white;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.proj__title {
  color: $white;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.coming_soon {
  @include bodyM;
  color: $pastel_violet;
}

// ----------------------------------- DARK MODE END -------------------

// ----------------------------------- LIGHT MODE -------------------

#light .proj__card {
  background-color: $alabaster;
}
#light .proj__card:hover {
  // background-color: $grape;
  border: solid $pastel_violet 1px;
  // .proj__title {
  //   color: $white;
  // }
}

#light .proj__title {
  color: $charleston_green;
  text-shadow: none;
}

#light .coming_soon {
  color: $grape;
}
// #light .card__btn_text {
//   color: $copper;
// }
