@import "../../styles/style-guide.scss";

.box_container {
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 2rem;
  border-bottom: 2px solid $davy_grey;
  margin-bottom: 3rem;
}
.box_container img {
  width: 116px;
  border-radius: 116px;
}
#light .box_container {
  border-color: $alabaster;
}
#light .box_container h6 {
  color: $dark_charcoal;
}
#light .box_container p {
  color: $dark_charcoal;
}
.box_container h6 {
  @include heading5;
  color: $white;
}
.box_container p {
  @include bodyS;
  color: $white;
}

.box_socials {
  display: flex;
  // flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}
.box_social_icon {
  font-size: 1.5rem;
  color: $pastel_violet;
}

.box_social_icon:hover {
  color: $copper;
}
