@import "../../styles/style-guide.scss";

@media only screen and (min-width: 1300px) {
  .thankYou_container {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .thankYou_container img {
    width: 40%;
  }
  .thankYou_message {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .thankYou_container h1 {
    @include heading1;
  }
}

@media (min-width: 1024px) and (max-width: 1300px) {
  .thankYou_container {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .thankYou_container img {
    width: 50%;
  }
  .thankYou_message {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .thankYou_container h1 {
    @include heading2;
  }
}
@media (min-width: 350px) and (max-width: 1024px) {
  .thankYou_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .thankYou_container img {
    width: 70%;
  }
  .thankYou_container h1 {
    @include heading3;
  }
  .thankYou_message {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
}

@media (max-width: 350px) {
  .thankYou_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .thankYou_container img {
    width: 80%;
  }
  .thankYou_container h1 {
    @include heading4;
  }
  .thankYou_message {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
.thankYou_container h1 {
  // @include heading1;
  color: $white;
}

#light .thankYou_container h1 {
  color: $dark_charcoal;
}
#light .thankYou_container h4 {
  color: $dark_charcoal;
}
#light .thankYou_container p {
  color: $dark_charcoal;
}
.thankYou_container h4 {
  @include heading5;
  color: $white;
}
.thankYou_container p {
  @include bodyM;
  color: $white;
}
.thankYou_container p a {
  color: $copper;
  font-weight: 700;
}
