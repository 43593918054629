@import "../../styles/style-guide.scss";

@media only screen and (min-width: 1300px) {
  .error_container {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .error_container img {
    width: 40%;
  }
  .error_container h1 {
    @include mediumTitle;
  }
  .error_container h4 {
    @include heading2;
  }
  .error_container p {
    @include bodyM;
  }
  .error_message {
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
}
@media (min-width: 1024px) and (max-width: 1300px) {
  .error_container {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .error_container img {
    width: 40%;
  }
  .error_container h1 {
    @include mediumTitle;
  }
  .error_container h4 {
    @include heading2;
  }
  .error_container p {
    @include bodyM;
  }
  .error_message {
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
}
@media (min-width: 350px) and (max-width: 1024px) {
  .error_container {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
  }
  .error_container img {
    width: 80%;
  }
  .error_container h1 {
    @include smallTitle;
  }
  .error_container h4 {
    @include heading3;
  }
  .error_container p {
    @include bodyM;
  }
  .error_message {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
}
@media (max-width: 350px) {
  .error_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
  }
  .error_container img {
    width: 80%;
  }
  .error_container h1 {
    @include XsmallTitle;
  }
  .error_container h4 {
    @include heading4;
  }
  .error_container p {
    @include bodyM;
  }
  .error_message {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
}

.error_container h1 {
  // @include mediumTitle;
  color: $white;
}

.error_container h4 {
  // @include heading2;
  color: $white;
}

.error_container p {
  // @include bodyM;
  color: $white;
}

#light .error_container h1 {
  color: $dark_charcoal;
}
#light .error_container h4 {
  color: $dark_charcoal;
}
#light .error_container p {
  color: $dark_charcoal;
}
