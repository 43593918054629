@import "../../styles/style-guide.scss";

#light .subscribe_form {
  background-color: #d4d4d2;
}
#light .subscribe_form input {
  background-color: $lotion;
}
.subscribe_form {
  width: 80%;
  background-color: $charleston_green;
  padding: 0.5rem;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  gap: 1rem;
}
.subscribe_form input {
  flex: 1;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  @include bodyS;
  color: $white;
  display: flex;
  //   width: 400px;
  height: 46px;
  padding: 15px 21px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 8px;
  background: $eerie_black;
  border: none;
}
.subscribe_form input:focus {
  outline: none !important;
  border: 1px solid $copper;
  // box-shadow: 0 0 10px $grape;
}
