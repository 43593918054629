@import "../../styles/style-guide.scss";

// ----------------------------------- DARK MODE -------------------
@media (min-width: 866px) {
  .sitemap {
    flex-direction: row;
    justify-content: space-between;
  }
  .navigation_links {
    flex-direction: row;

    gap: 8rem;
  }
  .contact_info {
    flex-direction: row;
  }
}
@media (min-width: 540px) and (max-width: 866px) {
  .sitemap {
    flex-direction: column;
    gap: 2rem;
    // justify-content: space-between;
  }
  .navigation_links {
    flex-direction: row;

    gap: 4rem;
  }
  .contact_info {
    flex-direction: row;
  }
}
@media (max-width: 540px) {
  .sitemap {
    flex-direction: column;
    gap: 2rem;
    // justify-content: space-between;
  }
  .navigation_links {
    flex-direction: column;

    gap: 4rem;
  }
  .contact_info {
    flex-direction: column;
    gap: 2rem;
  }
}
.footer_container {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 3rem 3rem 5rem 3rem;
  background-color: $eerie_black;
}

.sitemap {
  width: 100%;
  display: flex;
  // flex-direction: row;
  align-items: flex-start;
  // justify-content: space-between;
  gap: auto;
  margin-bottom: 2rem;
}
.footer-divider {
  border: none; /* Remove default border */
  border-top: 2px solid $dark_charcoal; /* Add a 1px solid border with your desired color */
  margin: 10px 0; /* Adjust margin as needed */
}
.footer_logo {
  width: 35px;
}
.navigation_links {
  display: flex;
  justify-content: space-between;
  // gap: 8rem;
}
.navigation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}
.navigation_links h5 {
  @include bodyL;
  font-weight: 800;
  color: $white;
}

.navigation_links p {
  cursor: pointer;
  @include bodyS;
  color: $alabaster;
}

.navigation_links p:hover {
  color: $copper;
}

.contact_info {
  display: flex;
  // flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.copyright {
  @include bodyS;
  color: $davy_grey;
}
.social_media {
  display: flex;
  // flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.social_icon_small {
  font-size: 1.5rem;
  color: $davy_grey;
}
.social_icon_small:hover {
  color: $copper;
}

// ----------------------------------- DARK MODE END -------------------

// ----------------------------------- LIGHT MODE -------------------

#light .footer_container {
  background-color: $alabaster;
}

#light .footer_logo {
  content: url("../../assets//images/purple_logo.png");
}

#light .footer-divider {
  border-top: 1px solid $davy_grey;
}

#light .navigation_links h5 {
  color: $dark_charcoal;
}
#light .navigation_links p {
  color: $dark_charcoal;
}
#light .navigation_links p:hover {
  color: $copper;
}
