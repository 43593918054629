@import "../../styles/style-guide.scss";

// ----------------------------------- DARK MODE -------------------

@media (max-width: 520px) {
  .contact_card {
    width: 80%;
    padding: 1rem;
    border-radius: 16px;
  }
  .contact_card h2 {
    @include heading5;
  }
}
@media (min-width: 520px) {
  .contact_card {
    width: 60%;
    padding: 2rem;
    border-radius: 22px;
  }
  .contact_card h2 {
    @include heading4;
  }
}
.contact_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background-color: $eerie_black;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
    0px 1px 2px 0px rgba(0, 0, 0, 0.3);
}
.contact_img {
  border-radius: 50%;
  border: 1px solid $copper;
  width: 116px;
}

.contact_card h2 {
  // @include heading4;
  color: $white;
  text-align: center;
}

.contact_card p {
  @include bodyS;
  color: $white;
  text-align: center;
}

.contact_card p span {
  display: block;
}

// ----------------------------------- DARK MODE END-------------------

// ----------------------------------- LIGHT MODE -------------------

#light .contact_card {
  background-color: $alabaster;
}
#light .contact_card h2 {
  color: $dark_charcoal;
}
#light .contact_card p {
  color: $dark_charcoal;
}
