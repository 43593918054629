@import "../../styles/style-guide.scss";

@media only screen and (min-width: 1824px) {
  .hero_section {
    width: 75%;
    margin-top: 4rem;
  }
  .hero_section h1 {
    @include bigTitle;
  }
  .hero_section h2 {
    @include mediumTitle;
  }
  .sub_header {
    @include heading2;
  }
  .details p {
    @include heading3;
    font-weight: 400;
  }
  /* ---------- sec2 ---------------*/
  .facts_aboutme {
    width: 80%;
    flex-direction: row;
  }
  .fact_list li {
    @include bodyL;
  }
  .me_img {
    width: 35%;
  }
  /* --------------my story section-----------------*/

  .inside_container {
    width: 80%;
    flex-direction: row;
  }
  .inside_container img {
    width: 35%;
  }

  .skills {
    flex-direction: row;
  }
  .design_skills h4 {
    @include bodyL;
  }
  .design_skills ul li {
    @include bodyM;
  }
  .skills img {
    width: 35%;
  }
}
@media (min-width: 1400px) and (max-width: 1824px) {
  .hero_section {
    margin-top: 4rem;
    width: 75%;
  }
  .hero_section h1 {
    @include mediumTitle;
  }
  .hero_section h2 {
    @include smallTitle;
  }
  .sub_header {
    @include heading4;
  }
  .details p {
    @include bodyL;
    font-weight: 400;
  }
  /* ---------- sec2 ---------------*/
  .facts_aboutme {
    width: 80%;
    flex-direction: row;
    gap: 3rem;
  }
  .fact_list li {
    @include bodyM;
  }
  .me_img {
    width: 45%;
  }

  /* --------------my story section-----------------*/

  .inside_container {
    width: 90%;
    flex-direction: row;
    gap: 3rem;
  }
  .inside_container img {
    width: 45%;
  }
  .skills {
    flex-direction: row;
  }
  .design_skills h4 {
    @include bodyL;
  }
  .design_skills ul li {
    @include bodyM;
  }
  .skills img {
    width: 35%;
  }
}
@media (min-width: 1081px) and (max-width: 1400px) {
  .hero_section {
    margin-top: 3rem;
    width: 85%;
  }
  .hero_section h1 {
    @include mediumTitle;
  }
  .hero_section h2 {
    @include smallTitle;
  }
  .sub_header {
    @include heading4;
  }
  .details p {
    @include bodyL;
    font-weight: 400;
  }
  /* ---------- sec2 ---------------*/
  .facts_aboutme {
    width: 90%;
    flex-direction: row;
    gap: 3rem;
  }
  .fact_list li {
    @include bodyM;
  }
  .me_img {
    width: 45%;
  }
  /* --------------my story section-----------------*/

  .inside_container {
    width: 90%;
    flex-direction: row;
    gap: 3rem;
  }
  .inside_container img {
    width: 45%;
  }
  .skills {
    flex-direction: row;
  }
  .design_skills h4 {
    @include bodyL;
  }
  .design_skills ul li {
    @include bodyM;
  }
  .skills img {
    width: 35%;
  }
}
@media (min-width: 1024px) and (max-width: 1081px) {
  .hero_section {
    margin-top: 3rem;
    width: 75%;
  }
  .hero_section h1 {
    @include smallTitle;
  }
  .hero_section h2 {
    @include XsmallTitle;
  }
  .sub_header {
    @include heading5;
  }
  .details p {
    @include bodyL;
  }
  /* ---------- sec2 ---------------*/
  .facts_aboutme {
    width: 90%;
    flex-direction: row;
    gap: 3rem;
  }
  .fact_list li {
    @include bodyS;
  }
  .me_img {
    width: 40%;
  }
  /* --------------my story section-----------------*/

  .inside_container {
    width: 100%;
    flex-direction: row;
    gap: 2rem;
  }
  .inside_container img {
    width: 50%;
  }
  .skills {
    flex-direction: row;
  }
  .design_skills h4 {
    @include bodyL;
  }
  .design_skills ul li {
    @include bodyS;
  }
  .skills img {
    width: 35%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .hero_section {
    width: 85%;
    margin-top: 3rem;
  }
  .hero_section h1 {
    @include smallTitle;
  }
  .hero_section h2 {
    @include heading1;
  }
  .sub_header {
    @include heading5;
  }
  .details p {
    @include bodyL;
  }
  /* ---------- sec2 ---------------*/
  .facts_aboutme {
    width: 90%;
    flex-direction: column;
    gap: 2rem;
  }
  .fact_list li {
    @include bodyM;
  }
  .me_img {
    order: 1;
    width: 80%;
  }
  .facts {
    order: 2;
  }
  /* --------------my story section-----------------*/

  .inside_container {
    width: 100%;
    flex-direction: column;
    gap: 2rem;
  }
  .inside_container img {
    width: 80%;
  }
  .skills {
    flex-direction: row;
    align-items: flex-start;
  }

  .design_skills h4 {
    @include bodyM;
  }
  .design_skills ul li {
    @include bodyS;
  }
  .skills img {
    width: 35%;
  }
}
@media (min-width: 540px) and (max-width: 767px) {
  .hero_section {
    width: 85%;
    margin-top: 2rem;
  }
  .hero_section h1 {
    @include XsmallTitle;
  }
  .hero_section h2 {
    @include heading2;
  }
  .sub_header {
    @include bodyL;
    font-weight: 700;
  }
  .details p {
    @include bodyM;
  }
  /* ---------- sec2 ---------------*/
  .facts_aboutme {
    width: 90%;
    flex-direction: column;
    gap: 2rem;
  }
  .fact_list li {
    @include bodyM;
  }
  .me_img {
    order: 1;
    width: 90%;
  }
  .facts {
    order: 2;
  }
  /* --------------my story section-----------------*/

  .inside_container {
    width: 100%;
    flex-direction: column;
    gap: 2rem;
  }
  .inside_container img {
    width: 80%;
  }
  .skills {
    flex-direction: column;
    align-items: flex-start;
  }
  .skills img {
    order: 1;
    width: 80%;
  }
  .design_skills {
    margin-left: 1rem;
    align-self: flex-start;
    order: 2;
  }
  .design_skills h4 {
    @include heading5;
  }
  .design_skills ul li {
    @include bodyL;
  }
}
@media (min-width: 350px) and (max-width: 540px) {
  .hero_section {
    width: 90%;
    margin-top: 2rem;
  }
  .hero_section h1 {
    @include heading1;
  }
  .hero_section h2 {
    @include heading3;
  }
  .sub_header {
    @include bodyL;
    font-weight: 700;
  }
  .details p {
    @include bodyM;
  }
  /* ---------- sec2 ---------------*/
  .facts_aboutme {
    width: 90%;
    flex-direction: column;
    gap: 2rem;
  }
  .fact_list li {
    @include bodyS;
  }
  .me_img {
    order: 1;
    width: 90%;
  }
  .facts {
    order: 2;
  }
  /* --------------my story section-----------------*/

  .inside_container {
    width: 100%;
    flex-direction: column;
    gap: 2rem;
  }
  .inside_container img {
    width: 100%;
  }
  .skills {
    flex-direction: column;
    align-items: flex-start;
  }
  .skills img {
    order: 1;
    width: 80%;
  }
  .design_skills {
    margin-left: 1rem;
    align-self: flex-start;
    order: 2;
  }
  .design_skills h4 {
    @include heading5;
  }
  .design_skills ul li {
    @include bodyL;
  }
}
@media (max-width: 350px) {
  .hero_section {
    width: 90%;
  }
  .hero_section h1 {
    @include heading1;
  }
  .hero_section h2 {
    @include heading3;
  }
  .sub_header {
    @include bodyM;
    font-weight: 700;
  }
  .details p {
    @include bodyS;
  }
  /* ---------- sec2 ---------------*/
  .facts_aboutme {
    width: 90%;
    flex-direction: column;
    gap: 2rem;
  }
  .fact_list li {
    @include bodyS;
  }
  .me_img {
    order: 1;
    width: 80%;
  }
  .facts {
    order: 2;
  }
  /* --------------my story section-----------------*/

  .inside_container {
    width: 100%;
    flex-direction: column;
    gap: 2rem;
  }
  .inside_container img {
    width: 100%;
  }
  .skills {
    flex-direction: column;
    align-items: flex-start;
  }
  .skills img {
    order: 1;
    width: 80%;
  }
  .design_skills {
    margin-left: 1rem;
    align-self: flex-start;
    order: 2;
  }
  .design_skills h4 {
    @include bodyL;
  }
  .design_skills ul li {
    @include bodyM;
  }
}

.hero_section {
  // margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  // width: 75%;
  gap: 3rem;
  margin-bottom: 4rem;
}

.hero_section h1 {
  // @include mediumTitle;
  color: $white;
  margin-bottom: 1rem;
}
.hero_section h2 {
  // @include smallTitle;
  color: $white;
}
.my_role,
.cursor {
  color: $pastel_violet;
}

.sub_header {
  // @include heading4;
  color: $white;
}

.details p {
  // @include bodyL;
  color: $white;
  //   width: 75%;
}

.facts_aboutme {
  // width: 90%;
  display: flex;
  // flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.me_img {
  // width: 45%;
  border-radius: 35px;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
    0px 1px 2px 0px rgba(0, 0, 0, 0.3);
}
.facts {
  // order: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
}
.fact_list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.fact_list li {
  // @include bodyM;
  color: $white;
}
li a {
  font-weight: 700;
}
li a:hover {
  color: $copper;
}

.story_section {
  background-color: $eerie_black;
  width: 100%;
  box-sizing: border-box;
  padding-top: 5rem;
  padding-bottom: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  // gap: 3rem;
  justify-content: center;
}
.story_container {
  width: 90%;
  display: flex;
  gap: 3rem;
  flex-direction: column;
}
.story_section .inside_container {
  // width: 90%;
  display: flex;
  // flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 6rem;
}
.story_text {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.inside_container h4 {
  @include heading5;
  color: $white;
}
.inside_container p {
  @include bodyL;
  color: $white;
}

.skills_section {
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-bottom: 6rem;
}
.skills {
  display: flex;
  // flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5rem;
}
.design_skills {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
}
.design_skills h4 {
  // @include bodyL;
  color: $white;
}
.design_skills ul {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  //   margin: 0;
  padding: 0;
}
.design_skills ul li {
  // @include bodyM;
  color: $white;
}

// --------------------- Dark Mode --------------------------------
#light .hero_section h1 {
  color: $dark_charcoal;
}
#light .hero_section h2 {
  color: $dark_charcoal;
}
#light .my_role {
  color: $copper;
}
#light .cursor {
  color: $copper;
}
#light .sub_header {
  color: $dark_charcoal;
}
#light .details p {
  color: $dark_charcoal;
}
#light .fact_list li {
  color: $dark_charcoal;
}
#light .story_section {
  background-color: $alabaster;
}
#light .design_skills h4 {
  color: $dark_charcoal;
}
#light .design_skills ul li {
  color: $dark_charcoal;
}
#light .inside_container h4 {
  color: $dark_charcoal;
}

#light .inside_container p {
  color: $dark_charcoal;
}
